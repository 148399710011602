$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; .fk-d-menu {
  .ai-post-helper {
    &__suggestion__text,
    &__suggestion__buttons {
      padding: 0.75em 1rem;
      margin: 0;
    }
  }
}
html.scrollable-modal {
  overflow: auto; // overrides core .modal-open class scroll lock
}

.ai-summary-modal {
  .d-modal__container {
    position: fixed;
    top: var(--header-offset);
    margin-top: 1em;
    right: 1em;
    width: 100vw;
    max-width: 30em;
    max-height: calc(
      100vh - var(--header-offset) - 3rem - var(--composer-height, 0px)
    );

    box-shadow: var(--shadow-menu-panel);
  }

  .fullscreen-composer & {
    display: none;
  }
}

.ai-summary-modal + .d-modal__backdrop {
  background: transparent; // allows for reading, but still triggers clickoutside event
}
